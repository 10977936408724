import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HelpConfig } from "src/app/core/config/help-config";

@Injectable({
    providedIn: "root",
  })

  export class RoyaltiesService {
    url = `${this.helpConfig.BASE_API}royalties/`;
  
    constructor(private http: HttpClient, private helpConfig: HelpConfig) {}
    
    obterRoyalties(data: any){
        let url = `${this.url}list`;
        return this.http.post(`${url}`, data);
    }

    obterPorIdEcv(id: string){
        let url = `${this.url}${id}`;
        return this.http.get(`${url}`);
    }

    adicionarCredito(data: any){
        let url = `${this.url}addcredit`;
        return this.http.post(`${url}`, data);
    }

    removerCredito(data: any){
        let url = `${this.url}cancelcredit`;
        return this.http.post(`${url}`, data);
    }

    adicionarECV(data: any){
        let url = `${this.url}`;
        return this.http.post(`${url}`, data);
    }

    getByID(id: string): Observable<any> {
        return this.http.get<any>(`${this.url}${id}`).pipe(
          map(response => response)
        );
    }
  }
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelpConfig } from '../core/config/help-config';

@Injectable({
  providedIn: 'root'
})
export class VersionamentoService {


  constructor(
    private http: HttpClient,
    private helpConfig: HelpConfig
  ) { }

  getVersionamento(){
    return this.http.get(this.helpConfig.BASE_API)
  }
}

import { UserTypeEnum } from '../../enums/user-type.enum';
import { LocalStorageService  } from 'ngx-webstorage';
//import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TokenService } from './token.service';
import { Variables } from '../../utils/variables';
import { LogoutType } from '../../enums/logout-types.enum';
import { UserLoginModel } from '../../models/outros/user-login.model';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class UserControllerService {

  // private userSubject = new BehaviorSubject<UserLoginModel>(null);
  private userSubject = new BehaviorSubject<UserLoginModel>({});
  // private user: UserLoginModel;
  private user: UserLoginModel = new UserLoginModel;
  private userType = UserTypeEnum;

  constructor(
    private storage: LocalStorageService,
    private tokenService: TokenService,
    //private toastrService: ToastrService,
    private router: Router
  ) { }

  setUser(user: UserLoginModel) {
    this.user = user;
    this.storage.store(Variables.USER_AUTH, user);
    this.userSubject.next(user);
  }

  getUserLogged(): UserLoginModel {
    return this.user;
  }

  isAdmin(): boolean {
    return (this.user.type == this.userType.ADMINISTRADOR)
  }

  isAdminDetran(): boolean {
    return (this.user.type == this.userType.ADMINISTRADOR_DETRAN)
  }

  isCandidate(): boolean {
    return (this.user.type == this.userType.ALUNO)
  }

  isInstructor(): boolean {
    return (this.user.type == this.userType.INSTRUTOR)
  }

  isOperator(): boolean {
    return (this.user.type == this.userType.OPERADOR_N1)
  }

  isSupervisor(): boolean {
    return (this.user.type == this.userType.SUPERVISOR)
  }

  isLogged() {
    return this.tokenService.hasToken() && this.tokenService.isTokenExpired();
  }

  getUser() {
    return this.userSubject.asObservable();
  }

  recoverFromStorage() {
    return this.storage.retrieve(Variables.USER_AUTH);
  }

  setFromStorage() {
    this.setUser(this.storage.retrieve(Variables.USER_AUTH))
  }

  getIp() {
    return this.storage.retrieve(Variables.IP_USER);
  }

  logout(type: LogoutType = LogoutType.Default) {
    switch (type) {
      case LogoutType.Inactivity:
        //this.toastrService.info('Você foi desconectado por inatividade! Por favor, faça o login novamente', 'Desconectado');
        break;
      case LogoutType.Unauthorized:
        //this.toastrService.info('Por motivos de autorização você foi desconectado do sistema. Por favor, faça o login novamente.', 'Desconectado');
        break;
      case LogoutType.Expires:
        //this.toastrService.info('Sua sessão expirou por inatividade. Por favor, faça o login novamente', 'Sua sessão expirou!');
        break;
      default:
        //this.toastrService.info('O logout foi efetuado com sucesso.', 'Logout!');
        break;
    }
    this.storage.clear(Variables.USER_AUTH);
    this.tokenService.removeToken();
    
    localStorage.removeItem('res');
    //this.userSubject.next(null);
    const path = '/login';
    this.router.navigate([path]);
  }

  getRoles(){
    return TokenToStorageAuthModel(this.storage.retrieve(Variables.USER_AUTH).access_token);
  }
}

export interface StorageAuthModel {
  given_name: string,
  email: string,
  roles: []
}

export function TokenToStorageAuthModel(access_token: any): StorageAuthModel {
  const jwtHelperService = new JwtHelperService();
  const token: any = jwtHelperService.decodeToken(access_token);
  return {
    email: token.email,
    given_name: token.given_name,
    roles: token.resource_access.rinspection.roles
  }
}

import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpConfig {

  constructor(private configService: ConfigService) {}

  public get BASE_API(): string { return this.configService.getUrl('RINSPECTION_WEB', 'URL'); }

  public get HOMOLGATION_API(): string { return this.configService.getEndPoint('RINSPECTION_WEB', 'HOMOLOGATION'); }

  public get IDENTITY_API(): string { return this.configService.getEndPoint('RINSPECTION_WEB', 'IDENTITY'); }
}

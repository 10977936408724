import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthGuard } from './core/guard/auth.guard';
import { Routes } from '@angular/router';

export const AppRoutes: Routes = [
    {
        path: '', redirectTo: '/home', pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./core/components/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [

            {
                path: 'home',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'gerenciamento',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/gerenciamento/gerenciamento.module').then(m => m.GerenciamentoModule)
            },
            {
                path: 'cadastro',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/cadastro/cadastro.module').then(m => m.CadastroModule)
            },
            {
                path: 'operacao',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/operacao/operacao.module').then(m => m.OperacaoModule)
            },
            {
                path: 'credito',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/credito/credito.module').then(m => m.CreditoModule)
            },
            {
                path: 'financeiro',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/financeiro/financeiro.module').then(m => m.FinanceiroModule)
            }, {
                path: 'suporte',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/suporte/suporte.module').then(m => m.SuporteModule)
            }, {
                path: 'ajuda',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/ajuda/ajuda.module').then(m => m.AjudaModule)
            }, {
                path: 'ecv',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/cadastro/ecv/ecv.module').then(m => m.EcvModule)
            }, {
                path: 'notafiscal',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/notaFiscal/notaFiscal.module').then(m => m.NotaFiscalModule)
            }, {
                path: 'auditoriaDETRAN',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/auditoriaDetran/auditoriaDetran.module').then(m => m.AuditoriaDetranModule)
            }, {
                path: 'comercial',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./pages/comercial/comercial.module').then(m => m.ComercialModule)
            },
        ]
    },
    {
        path: '**', redirectTo: '/home', pathMatch: 'full'
    }
];

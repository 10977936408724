import { RoleModel } from '../identidade/role.model';
export class UserLoginModel {
  access_token?: string;
  associado?: boolean;
  date_token_expire?: any;
  expires_in?: string;
  id?: string;
  jti?: string;
  marcacaoExame?: boolean;
  name?: string;
  organizationCNPJ?: string;
  organizationCidade?: string;
  organizationEstado?: string;
  organizationNome?: string;
  refresh_token?: string;
  relatorioMarcacao?: boolean;
  scope?: string;
  token_type?: string;
  type?: string;
  userName?: string;
  //cfc?: CfcModel;
  //cfcMonitoramento?: CfcMonitoramentoModel;
  matriculaId?: number;
  matriculaIdExterno?: number;
  matriculaNumeroProcesso?: string;
  marcacaoExameEspecial?: boolean;
  idLocalMarcacaoFotoBiometria?: number;
  roles?: RoleModel[];
  image?: string;

  constructor(options: UserLoginModel = {}) {
    this.access_token = options.access_token || undefined;
    this.associado = options.associado || undefined;
    //this.cfc = options.cfc || null;
    //this.cfcMonitoramento = options.cfcMonitoramento || null;
    this.date_token_expire = options.date_token_expire || null;
    this.expires_in = options.expires_in || undefined;
    this.id = options.id || undefined;
    this.jti = options.jti || undefined;
    this.marcacaoExame = options.marcacaoExame || undefined;
    this.name = options.name || undefined;
    this.organizationCNPJ = options.organizationCNPJ || undefined;
    this.organizationCidade = options.organizationCidade || undefined;
    this.organizationEstado = options.organizationEstado || undefined;
    this.organizationNome = options.organizationNome || undefined;
    this.refresh_token = options.refresh_token || undefined;
    this.relatorioMarcacao = options.relatorioMarcacao || undefined;
    this.scope = options.scope || undefined;
    this.token_type = options.token_type || undefined;
    this.type = options.type || undefined;
    this.userName = options.userName || undefined;
    this.matriculaId = options.matriculaId || undefined;
    this.matriculaIdExterno = options.matriculaIdExterno || undefined;
    this.matriculaNumeroProcesso = options.matriculaNumeroProcesso || undefined;
    this.marcacaoExameEspecial = options.marcacaoExameEspecial || undefined;
    this.idLocalMarcacaoFotoBiometria = options.idLocalMarcacaoFotoBiometria || undefined;
    this.roles = options.roles || new Array<RoleModel>();
    this.image = options.image || undefined;
  }
}

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserControllerService } from '../services/outros/user-controller.service';

@Directive({
  selector: '[permission]'
})
export class PermissionDirective implements OnInit {

  @Input() permission!: [string];

  constructor(
    private userControllerService: UserControllerService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    let roles: any = this.userControllerService.getRoles().roles;
    if (roles.some(r => this.permission.includes(r))) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.viewContainer.clear();
  }
}

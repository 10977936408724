export class Variables {
    public static STORAGE_AUTH = 'STORAGE_AUTH';
    public static USER_AUTH = 'USER_AUTH';
    public static USER_LOGIN = 'USER_LOGIN';
    public static CLASS_STORAGE = 'CLASS_STORAGE';
    public static METTING_STORAGE = 'METTING_STORAGE';
    public static SHOW_HELP_ACCESS_CLASS = 'SHOW_HELP_ACCESS_CLASS';
    public static SHOW_CAMERA_MESSAGE = 'SHOW_CAMERA_MESSAGE';
    public static IP_USER = 'IP_USER';
    public static PRODUCTS = 'PRODUCTS';
    public static FINANCIAL_CANDIDATE_SITUATION = 'FINANCIAL_CANDIDATE_SITUATION';
    public static SHOW_MARK_MESSAGE = 'SHOW_MARK_MESSAGE';
    public static PERMISSIONS = 'PERMISSIONS';
    public static ROLES = 'ROLES';
    public static MENU = 'MENU';
    public static CHECK_SOM = 'CHECK_SOM';
    public static MOCK_MONITORAMENTO = 'MOCK_MONITORAMENTO';
    public static CLASS_STATE = 'CLASS_STATE';
  }
  
<footer class="footer border-top border-6">
  <div class="container-fluid">
    <div class="row justify-content-between align-items-center"> 
      <div class="col-6 text-left">
        <button class="btn-suporte"> 
            <a href="https://api.whatsapp.com/send/?phone=5571999403953&text=Ol%C3%A1&type=phone_number&app_absent=0%27" target="_blank" >
            <div>
              <img src="./../../assets/img/bg-03.png" alt="wpp" class="wpp" />
            </div>
          </a>
          Suporte
          </button>
      </div>
      <div class="col-6 text-right">
        &copy;
        {{test | date: 'yyyy'}},
        <a href="https://renova.net.br/site/" target="_blank">Renova Soluções</a>  em Tecnologia.
      </div>
    </div>
  </div>
</footer>

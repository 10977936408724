import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LocalStorageService  } from 'ngx-webstorage';
import { Token } from '../../outros/token.model';
import { UserLoginModel } from '../../models/outros/user-login.model';
import { Variables } from '../../utils/variables';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private storage: LocalStorageService
  ) { }

  hasToken() {
    return !!this.getToken();
  }

  /**TODO: REFATORAR PARA GUARDAR APENAS O TOKEN */
  setToken(user: UserLoginModel) {

    let tokenData: Token = {
      access_token: '',
      date_token_expire: '',
    //   expires_in: null,
      expires_in: '',
      refresh_token: '',
      token_type: ''
    }

    tokenData.access_token = user.access_token as string;
    tokenData.date_token_expire = user.date_token_expire;
    tokenData.expires_in = user.expires_in as string;
    tokenData.refresh_token = user.refresh_token as string;
    tokenData.token_type = user.token_type as string;
    this.storage.store(Variables.STORAGE_AUTH, tokenData);
  }

  getToken(): Token {
    return this.storage.retrieve(Variables.STORAGE_AUTH);
  }

  removeToken() {
    this.storage.clear(Variables.STORAGE_AUTH);
  }

  isTokenExpired() {
    const token: Token = this.storage.retrieve(Variables.STORAGE_AUTH);
    if (token) {
      return moment(new Date()).isAfter(token.date_token_expire);
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { HelpConfig } from 'src/app/core/config/help-config';
import { IUserRequest } from '../interface/IUserRequest';
import { IUserResponse } from '../interface/IUserResponse';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = `${this.helpConfig.BASE_API}user`;

  constructor(
    private http: HttpClient,
    private helpConfig: HelpConfig
  ) { }

  list(requisition): Observable<any> {
    return this.http.post<any>(`${this.url}/list`, requisition).pipe(
      map(res => res)
    );
  }

  getByID(id: string): Observable<IUserResponse> {
    return this.http.get<IUserResponse>(`${this.url}/${id}`).pipe(
      map(response => response)
    );
  }

  getByLogin(login: string) {
    return this.http.get(`${this.url}/bylogin?login=${login}`);
  }

  delete(id: string): Observable<any> {
    const urlWithParams = `${this.url}?id=${id}`;
    return this.http.delete<any>(urlWithParams);
  }

  update(requisition): Observable<any> {
    return this.http.put<any>(this.url, requisition)
  }

  save(record: IUserRequest): Observable<any> {
    const recordJson = JSON.stringify(record);
    return this.http.post(this.url, recordJson, { headers: { 'Content-Type': 'application/json' } });
  }

  activatedeactivate(user: any) {
    return this.http.put(`${this.url}/activatedeactivate`, user);
  }
}

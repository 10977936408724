import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { UserControllerService } from '../core/services/outros/user-controller.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  
  private isRefreshing = false;

  constructor(
    private userControllerService: UserControllerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authToken = this.userControllerService.recoverFromStorage();
    if (!!authToken) {
      const modifiedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken.access_token}`),
      });
      return next.handle(modifiedReq).pipe(
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            !req.url.includes('auth/signin') &&
            error.status === 401
          ) {

            localStorage.removeItem('ngx-webstorage|user_auth'); //ver maneira adequada de fazer isto
            window.location.href = 'login'; //ver maneira adequada de fazer isto
            return this.handle401Error(req, next);
          }

          return throwError(() => error);
        })
      );
    }
    return next.handle(req.clone());
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // if (!this.isRefreshing) {
    //   this.isRefreshing = true;

    //   if (this.storageService.isLoggedIn()) {
    //     return this.authService.refreshToken().pipe(
    //       switchMap(() => {
    //         this.isRefreshing = false;

    //         return next.handle(request);
    //       }),
    //       catchError((error) => {
    //         this.isRefreshing = false;

    //         // if (error.status == '403') {
    //         //   this.eventBusService.emit(new EventData('logout', null));
    //         // }

    //         return throwError(() => error);
    //       })
    //     );
    //   }
    // }

    return next.handle(request);
  }
}
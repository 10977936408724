import { ActivatedRouteSnapshot, CanActivateChild, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from '../services/outros/token.service';
import { UserControllerService } from '../services/outros/user-controller.service';
import { LogoutType } from '../enums/logout-types.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    public router: Router,
    private tokenService: TokenService,
    private userControolerService: UserControllerService
  ) { }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (state.url !== '/') {
      if (this.userControolerService.recoverFromStorage()) {
        this.userControolerService.setFromStorage();
      } else if (this.tokenService.isTokenExpired()) {
        this.userControolerService.logout(LogoutType.Expires);
        this.router.navigate(['/login']);
        return false;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }
    return true;
  }
}


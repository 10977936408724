import { Component } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-spinner',
  template: `
<div *ngIf="this.loader.getLoading()" class="cssload-container">
  <div class="cssload-speeding-wheel"></div>
</div>  
  `,
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
  constructor(public loader: LoaderService) { }
}
